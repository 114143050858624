import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/img/first.png';
import _imports_1 from '@/assets/img/second.png';
import _imports_2 from '@/assets/img/third.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-585c92a6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "rankListItem flex align-center"
};
var _hoisted_2 = {
  key: 0,
  class: "logo",
  src: _imports_0
};
var _hoisted_3 = {
  key: 1,
  class: "logo",
  src: _imports_1
};
var _hoisted_4 = {
  key: 2,
  class: "logo",
  src: _imports_2
};
var _hoisted_5 = {
  key: 3,
  class: "num"
};
var _hoisted_6 = {
  class: "info flex-1"
};
var _hoisted_7 = {
  class: "weight"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.index === 0 ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), _ctx.index === 1 ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), _ctx.index === 2 ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true), _ctx.index > 2 ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.index + 1), 1)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.item.customer_name), 1), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.item.settle_value) + _toDisplayString(_ctx.unitFormat(_ctx.type)) + "/" + _toDisplayString(_ctx.item.tot_money) + "万元", 1)]);
}