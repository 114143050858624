import hyRequest from '@/service';
import { IProductionData, IProductionDataParams } from './type';

enum DashboardApi {
  getProductionData = '/product/qryProdKwh'
}

// 骨料汇总及各料类销售数据
export function getProductionData(data: IProductionDataParams) {
  return hyRequest.post<IProductionData>({
    url: DashboardApi.getProductionData,
    data
  });
}
