import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-27796f8a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "SalePrice"
};
var _hoisted_2 = {
  class: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_type_tab = _resolveComponent("c-type-tab");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_type_tab, {
    class: "mb-2",
    onConfirmType: _ctx.confirmType,
    typeTabs: ['骨料', '商砼', '砂浆']
  }, null, 8, ["onConfirmType"]), (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.curTabComponent), {
    type: _ctx.tabIndex
  }, null, 8, ["type"]))])]);
}