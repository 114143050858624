/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  name: 'RankListItem',
  props: {
    type: {
      type: String,
      default: 'aggregate' //'aggregate' | 'mortar' | 'shangtong'
    },
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup: function setup() {
    var unitFormat = function unitFormat(val) {
      var text = '';
      if (val === 'aggregate' || val === 'mortar') {
        text = '吨';
      }
      if (val === 'shangtong') {
        text = '方';
      }
      return text;
    };
    return {
      unitFormat: unitFormat
    };
  }
});