import hyRequest from '@/service';
import { IDetailParams, ISaleDetailData } from './type';

enum DashboardApi {
  getAggregateSaleDetail = '/saleData/aggregateSaleDetail',
  getCommercialSaleDetail = '/saleData/commercialSaleDetail',
  getMortarSaleDetail = '/saleData/mortarSaleDetail'
}

// 查询骨料销售明细
export function getAggregateSaleDetail(data: IDetailParams) {
  return hyRequest.post<ISaleDetailData>({
    url: DashboardApi.getAggregateSaleDetail,
    data
  });
}
// 查询商砼销售明细
export function getCommercialSaleDetail(data: IDetailParams) {
  return hyRequest.post<ISaleDetailData>({
    url: DashboardApi.getCommercialSaleDetail,
    data
  });
}
// 查询砂浆销售明细
export function getMortarSaleDetail(data: IDetailParams) {
  return hyRequest.post<ISaleDetailData>({
    url: DashboardApi.getMortarSaleDetail,
    data
  });
}
