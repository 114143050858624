/* unplugin-vue-components disabled */import "core-js/modules/es.array.push.js";
import { useEmptyStringFormat } from '@/hooks/fn';
export default defineComponent({
  name: 'PlanPart',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var router = useRouter();
    var toPlanDetail = function toPlanDetail() {
      router.push({
        name: 'PlanDetail'
      });
    };
    return {
      emptyStringFormat: emptyStringFormat,
      toPlanDetail: toPlanDetail
    };
  }
});