import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  IAggregateSalePrice,
  ISaleRank,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend
} from './type';
import hyRequest from '@/service';

enum DashboardApi {
  getAggregateSaleData = '/saleData/aggregateSaleData',
  getAggregateSaleContrastData = '/saleData/aggregateSaleContrastData',
  getAggregateSaleCommercialData = '/commercialSalesPrice/highestPrice',
  getAggregateSaleCommercialDataList = '/commercialSalesPrice/salesPriceList',
  getAggregateSalemortarData = '/mortarSalesPrice/highestPrice',
  getAggregateSalemortarDataList = '/mortarSalesPrice/salesPriceList',
  getAggregateSalePriceData = '/aggregateSalesPrice/newestPrice',
  getAggregateSalehistoryPriceTrend = '/aggregateSalesPrice/historyPriceTrend',
  findDayStoneSales = '/report/sales/findDayStoneSales',
  getAggregateSaleRank = '/saleData/aggregateSaleRank',
  getAggregateSaleTrend = '/saleData/aggregateSaleTrend'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleData>({
    url: DashboardApi.getAggregateSaleData,
    data
  });
}
// 骨料汇总及各料类销售数据表格版数据
export function findDayStoneSales(startTime: string) {
  return hyRequest.get<any>({
    url: `${DashboardApi.findDayStoneSales}?startTime=${startTime}`
  });
}

//骨料销售对比数据
export function getAggregateSaleContrastData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  });
}

//商砼销售对比数据
export function getAggregateSaleCommercialData() {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleCommercialData
  });
}

//商砼销售单条数据
export function getAggregateSaleCommercialDataList(data: {
  stone_id: number;
  stone_spec_id: number;
}) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleCommercialDataList,
    data
  });
}

//砂浆销售对比数据
export function getAggregateSalemortarData() {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSalemortarData
  });
}

//砂浆销售单条数据
export function getAggregateSalemortarDataList(data: {
  stone_id: number;
  stone_spec_id: number;
}) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSalemortarDataList,
    data
  });
}

// 骨料汇总及各料类销售价格
export function getAggregateSalePriceData() {
  return hyRequest.get<IAggregateSalePrice>({
    url: DashboardApi.getAggregateSalePriceData
  });
}

// 单个料类销售价格
export function getAggregateSalePriceOneData(data: {
  stone_id: number;
  stone_spec_id: number;
}) {
  return hyRequest.post<IAggregateSalePrice>({
    url: DashboardApi.getAggregateSalehistoryPriceTrend,
    data: data
  });
}

//骨料销售客户金额日排行榜
export function getAggregateSaleRank(data: IAggregateSaleDataParams) {
  return hyRequest.post<ISaleRank>({
    url: DashboardApi.getAggregateSaleRank,
    data: data
  });
}

//骨料销售趋势数据
export function getAggregateSaleTrend(data: IAggregateSaleTrendParams) {
  return hyRequest.post<IAggregateSaleTrend>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  });
}
