import {
  IAggregateSaleData,
  IAggregateSaleDataParams,
  IAggregateSaleContrastData,
  ISaleRank,
  IAggregateSaleTrendParams,
  IAggregateSaleTrend
} from './type';
import hyRequest from '@/service';

enum DashboardApi {
  getAggregateSaleData = '/saleData/mortarSaleData',
  getAggregateSaleContrastData = '/saleData/mortarSaleContrastData',
  getAggregateSaleRank = '/saleData/mortarSaleRank',
  getAggregateSaleTrend = '/saleData/mortarSaleTrend'
}

// 骨料汇总及各料类销售数据
export function getAggregateSaleData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleData>({
    url: DashboardApi.getAggregateSaleData,
    data
  });
}

//骨料销售对比数据
export function getAggregateSaleContrastData(data: IAggregateSaleDataParams) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleContrastData,
    data
  });
}
//骨料销售客户金额日排行榜
export function getAggregateSaleRank(data: IAggregateSaleDataParams) {
  return hyRequest.post<ISaleRank>({
    url: DashboardApi.getAggregateSaleRank,
    data: data
  });
}

//骨料销售趋势数据
export function getAggregateSaleTrend(data: IAggregateSaleTrendParams) {
  return hyRequest.post<IAggregateSaleTrend>({
    url: DashboardApi.getAggregateSaleTrend,
    data: data
  });
}
