import {
  IAggregateSaleContrastData,
  IAggregateSalePrice,
  IAggregateSalePriceForStoneItem,
  IMortarPriceForStoneItem,
  ISalePriceListItem
} from './type';
import hyRequest from '@/service';

enum DashboardApi {
  getAggregateSaleCommercialData = '/commercialSalesPrice/highestPrice',
  getAggregateSaleCommercialDataList = '/commercialSalesPrice/salesPriceList',
  getAggregateSalemortarData = '/mortarSalesPrice/highestPrice',
  getAggregateSalemortarDataList = '/mortarSalesPrice/salesPriceList',
  getAggregateSalePriceData = '/aggregateSalesPrice/newestPrice',
  getAggregateSalehistoryPriceTrend = '/aggregateSalesPrice/historyPriceTrend',
  getAggregateSales = '/aggregateSalesPrice/aggregateSales',
  getAggregateSalesPriceForStone = '/aggregateSalesPrice/aggregateSalesPriceForStone',
  getHistoryPriceTrendForStone = '/aggregateSalesPrice/historyPriceTrendForStone',
  getCommercialSales = '/commercialSalesPrice/commercialSales',
  getCommercialSalesPriceForStone = '/commercialSalesPrice/commercialSalesPriceForStone',
  getMortarSales = '/mortarSalesPrice/mortarSales',
  getMortarSalesPriceForStone = '/mortarSalesPrice/mortarSalesPriceForStone',
  getSalesPriceList = '/aggregateSalesPrice/aggregateSalesWithSalesPriceList'
}

//商砼销售对比数据
export function getAggregateSaleCommercialData() {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleCommercialData
  });
}

//商砼销售单条数据
export function getAggregateSaleCommercialDataList(data: {
  stone_id: number;
  stone_spec_id: number;
}) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSaleCommercialDataList,
    data
  });
}

//砂浆销售对比数据
export function getAggregateSalemortarData() {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSalemortarData
  });
}

//砂浆销售单条数据
export function getAggregateSalemortarDataList(data: {
  stone_id: number;
  stone_spec_id: number;
}) {
  return hyRequest.post<IAggregateSaleContrastData>({
    url: DashboardApi.getAggregateSalemortarDataList,
    data
  });
}

// 骨料汇总及各料类销售价格
export function getAggregateSalePriceData() {
  return hyRequest.get<IAggregateSalePrice>({
    url: DashboardApi.getAggregateSalePriceData
  });
}

// 单个料类销售价格
export function getAggregateSalePriceOneData(data: {
  stone_id: number;
  stone_spec_id: number;
}) {
  return hyRequest.post<IAggregateSalePrice>({
    url: DashboardApi.getAggregateSalehistoryPriceTrend,
    data: data
  });
}

//获取骨料列表
export function getAggregateSales() {
  return hyRequest.post<{ list: { stone_name: string }[] }>({
    url: DashboardApi.getAggregateSales
  });
}

//根据料类查询相关规格最新价格

export function getAggregateSalesPriceForStone(params: { stoneName: string }) {
  return hyRequest.post<{ list: IAggregateSalePriceForStoneItem[] }>({
    url: DashboardApi.getAggregateSalesPriceForStone,
    data: params
  });
}
//骨料历史价格趋势（根据名称查询）
export function getHistoryPriceTrendForStone(params: {
  stoneName: string;
  stoneSpecName: string;
}) {
  return hyRequest.post<{ list: IAggregateSalePriceForStoneItem[] }>({
    url: DashboardApi.getHistoryPriceTrendForStone,
    data: params
  });
}

export function getCommercialSales() {
  return hyRequest.post<{ list: { stone_name: string }[] }>({
    url: DashboardApi.getCommercialSales
  });
}
export function getMortarSales() {
  return hyRequest.post<{ list: { stone_name: string }[] }>({
    url: DashboardApi.getMortarSales
  });
}

export function getCommercialSalesPriceForStone(params: { stoneName: string }) {
  return hyRequest.post<{ list: IMortarPriceForStoneItem[] }>({
    url: DashboardApi.getCommercialSalesPriceForStone,
    data: params
  });
}
export function getMortarSalesPriceForStone(params: { stoneName: string }) {
  return hyRequest.post<{ list: IMortarPriceForStoneItem[] }>({
    url: DashboardApi.getMortarSalesPriceForStone,
    data: params
  });
}

//查询骨料列表及相关规格最新价格
export function getSalesPriceList() {
  return hyRequest.post<{ list: ISalePriceListItem[] }>({
    url: DashboardApi.getSalesPriceList
  });
}
