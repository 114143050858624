/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.number.constructor.js";
// import { useEmptyStringFormat } from '@/hooks/fn';
import localCache from '@/utils/localCache';
import CTypeTab from '@/components/CTypeTab.vue';
import useSaleData from './hooks/useSaleData';
import CNoData from '@/components/CNoData.vue';
export default defineComponent({
  name: 'ReportDetail',
  components: {
    CTypeTab: CTypeTab,
    CNoData: CNoData
  },
  setup: function setup() {
    // const formatDate = (date?: Date) => {
    //   return dayjs(date).format('YYYY/MM/DD');
    // };
    var formatDate1 = function formatDate1(date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss');
    };
    var route = useRoute();
    // const defaultDate = ref('')
    var reportType = ref('aggregate');
    // 销量单位
    var saleUnit = computed(function () {
      if (reportType.value === 'shangTong') {
        return '方';
      }
      return '吨';
    });
    var dateType = ref(0);
    var params = ref({
      date: dayjs().format('YYYY/MM/DD'),
      type: 0,
      pageNumber: 1,
      pageSize: 10
    });
    if (route.params.reportType) {
      localCache.setItem('defaultReportType', route.params.reportType);
    }
    if (route.params.type) {
      localCache.setItem('defaultDateType', route.params.type);
    }
    var localCacheReportType = localCache.getItem('defaultReportType');
    if (localCacheReportType) {
      reportType.value = localCacheReportType;
    }
    var localCacheDateType = localCache.getItem('defaultDateType');
    if (localCacheDateType) {
      dateType.value = Number(localCacheDateType);
    }
    if (dateType.value) {
      params.value.type = dateType.value;
      if (dateType.value === 4) {
        params.value.date = dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD');
      } else {
        params.value.date = dayjs().format('YYYY/MM/DD');
      }
    }
    provide('defaultDateType', readonly(dateType));
    onBeforeUnmount(function () {
      localCache.deleteItem('defaultDateType');
    });
    var confrimDate = function confrimDate(val) {
      params.value.date = val;
      params.value.pageNumber = 1;
      list.value = [];
      finished.value = false;
      loading.value = false;
      getDetailAsync();
    };
    var dateRef = ref();
    var confirmType = function confirmType(val) {
      var _dateRef$value;
      params.value.type = val;
      //把所以日期控件的日期变为当前最新日期
      (_dateRef$value = dateRef.value) === null || _dateRef$value === void 0 || _dateRef$value.reset();
      if (val === 4) {
        params.value.date = dayjs().format('YYYY/MM/DD') + ',' + dayjs().format('YYYY/MM/DD');
      } else {
        params.value.date = dayjs().format('YYYY/MM/DD');
      }
      params.value.pageNumber = 1;
      list.value = [];
      finished.value = false;
      loading.value = false;
      getDetailAsync();
    };
    var _useSaleData = useSaleData(params, reportType),
      _useSaleData2 = _slicedToArray(_useSaleData, 6),
      totalData = _useSaleData2[0],
      list = _useSaleData2[1],
      loading = _useSaleData2[2],
      finished = _useSaleData2[3],
      getDetailAsync = _useSaleData2[4],
      onLoad = _useSaleData2[5];
    onMounted(function () {
      getDetailAsync();
    });
    return {
      params: params,
      confirmType: confirmType,
      confrimDate: confrimDate,
      list: list,
      loading: loading,
      finished: finished,
      onLoad: onLoad,
      reportType: reportType,
      dateType: dateType,
      totalData: totalData,
      formatDate1: formatDate1,
      dateRef: dateRef,
      saleUnit: saleUnit
    };
  }
});